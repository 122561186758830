import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
    ChevronLeft,
    Close,
    Send,
    ContentCopy,
    ThumbDownOffAlt,
    ThumbUpOffAlt,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    ThumbUp,
    ThumbDown,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
    Button,
    IconButton,
    FormControl,
    OutlinedInput,
    InputAdornment,
    MobileStepper,
    FormHelperText,
    Box as Div,
} from '@mui/material';
import { powerRefinement } from "../../services/summon-api";
import { useAccessToken } from "../../services/auth0";
import { PowerRunning } from "./power-running";
import { FeedbackDialog } from "../feedback-dialog/feedback-dialog";

import './power-result.css';
import './power-running.css';

export function PowerResult(props) {
    const { result, power, persona, prompt, source, clearResult, errorMessage: newErroMessage, usePersona } = props;
    const accessToken = useAccessToken();
    const [attempts, setAttempts] = useState(5);
    const [slides, setSlides] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newPrompt, setNewPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [feedbackDirection, setFeedbackDirection] = useState(1);
    const theme = useTheme();
    const [interactionStart, setInteractionStart] = useState(0);

    const handleInteractionStart = (clientX) => {
        setInteractionStart(clientX);
    };
    
    const handleInteractionEnd = (clientX) => {
        const deltaX = clientX - interactionStart;
        const sensitivity = 100; // Adjust this value for faster/slower dragging
    
        if (deltaX > sensitivity && activeStep - 1 >= 0) {
            handleBack();
        } else if (deltaX < -sensitivity && activeStep + 1 < slides.length) {
            handleNext();
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        let output;
        if (newErroMessage) {
            output = `Oops, something went wrong:\n${newErroMessage}`;
        } else {
            output = result?.output ? result.output : ""
        }
        const updatedMsgs =  [
            {
                role: 'user',
                content: prompt
            },
            {
                role: 'assistant',
                content: output,
            }];
        setMessages(updatedMsgs);
        setAttempts(4);
        setErrorMessage(newErroMessage);
        const updatedSlides = [
            {
                prompt: prompt,
                response: output,
                vote: undefined
            }
        ]
        setSlides(updatedSlides);
        setActiveStep(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result?.output, newErroMessage])

    const handlePromptChange = (event) => {
        setNewPrompt(event.target.value);
    };

    const navigate = useNavigate();
    const handleCopy = async () => {
        try {
            let output = result.output;
            if (source) {
                output += `\n\nSource: ${source}`
            }
            output += "\n\nPowered by getwispy.ai";
            await navigator.clipboard.writeText(output);
        } catch (error) {
            console.error("Failed to copy:", error);
        }
    }

    const handleSubmit = async () => {
        try {
            if (attempts > 0) {
                let updatedSlides = [
                    ...slides,
                    {
                        prompt: newPrompt,
                        response: '',
                        vote: undefined,
                    }
                ]
                setSlides(updatedSlides);
                setActiveStep(slides.length);
                setIsLoading(true);
                setAttempts(attempts - 1)
                let updatedMsgs = [
                    ...messages,
                    {
                        role: 'user',
                        content: newPrompt
                    }
                ];
                setMessages(updatedMsgs)
                let data;
                if (usePersona) {
                    data = await powerRefinement(accessToken, power._id, power, updatedMsgs, persona._id)
                } else {
                    data = await powerRefinement(accessToken, power._id, power, updatedMsgs)
                }
                updatedMsgs = [
                    ...updatedMsgs,
                    {
                        role: 'assistant',
                        content: data.output
                    }
                ];
                setMessages(updatedMsgs);
                updatedSlides = [
                    ...slides,
                    {
                        prompt: newPrompt,
                        response: data.output,
                        vote: undefined,
                    }
                ]
                setSlides(updatedSlides);
                setActiveStep(slides.length);
                setNewPrompt('');
            }
        } catch (error) {
            setErrorMessage(error.message);
            const updatedMsgs =  [
                ...messages,
                {
                    role: 'user',
                    content: newPrompt,
                },
                {
                    role: 'assistant',
                    content: `Oops, something went wrong:\n${error.message}`,
                }
            ];
            const updatedSlides = [
                ...slides,
                {
                    prompt: newPrompt,
                    response: `Oops, something went wrong:\n${error.message}`,
                    vote: undefined
                }
            ]
            setMessages(updatedMsgs);
            setAttempts(attempts - 1)
            setSlides(updatedSlides);
            setActiveStep(slides.length);
            setNewPrompt('');
        } finally {
            setIsLoading(false);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleFeedbackPress = (direction) => {
        setFeedbackDirection(direction);
        setIsOpen(true);
    }

    const handleFeedbackSubmitted = () => {
        const updatedSlides = [
            ...slides,
        ]
        updatedSlides[activeStep] = {
            ...updatedSlides[activeStep],
            vote: feedbackDirection,
        }
        setSlides(updatedSlides);
    }

    return (
        <Div className="lb_content-layout">
            <FeedbackDialog
                open={isOpen}
                setOpen={setIsOpen}
                feedbackDirection={feedbackDirection}
                powerID={power._id}
                resultNumber={activeStep}
                totalRefinements={slides.length}
                handleFeedbackSubmitted={handleFeedbackSubmitted}
            />
            <Div id="lb_power-result">
                <Div id="lb_header_row">
                    <Div id="lb_modal_close">
                        <Close className="lb_close" onClick={() => navigate("/home")} />
                    </Div>
                </Div>
                <Div id="lb_power-form">
                        <Div id="lb_power-result-box" className="lb_content_box">
                            {slides[activeStep]?.response && <Div id="lb_power-result-actions">
                                <IconButton
                                    className="lb_action"
                                    id="lb_modal_copy"
                                    size="small"
                                    color="secondary"
                                    onClick={handleCopy}
                                >
                                    <ContentCopy />
                                </IconButton>
                                <IconButton
                                    className="lb_action"
                                    id="lb_modal_like"
                                    size="small"
                                    color="secondary"
                                    onClick={() => handleFeedbackPress(1)}
                                    disabled={!!slides[activeStep]?.vote}
                                >
                                    {slides[activeStep]?.vote === 1 ? <ThumbUp /> : <ThumbUpOffAlt />}
                                </IconButton>
                                <IconButton
                                    className="lb_action"
                                    id="lb_modal_dislike"
                                    size="small"
                                    color="secondary"
                                    onClick={() => handleFeedbackPress(-1)}
                                    disabled={!!slides[activeStep]?.vote}
                                >
                                    {slides[activeStep]?.vote === -1 ? <ThumbDown /> : <ThumbDownOffAlt />}

                                </IconButton>
                            </Div>}
                            <Div sx={{ flexGrow: 1 }} className="result-carousel">
                                <Div className="slide-container"
                                    onTouchStart={(e) => handleInteractionStart(e.touches[0].clientX)}
                                    onTouchEnd={(e) => handleInteractionEnd(e.changedTouches[0].clientX)}
                                    onMouseDown={(e) => handleInteractionStart(e.clientX)}
                                    onMouseUp={(e) => handleInteractionEnd(e.clientX)}
                                >
                                    {
                                        activeStep - 1 >= 0 && 
                                        (slides[activeStep - 1]?.response ?
                                        <Div className="result-box content-box prev-slide">
                                            {(activeStep - 1 > 0 && !errorMessage) && <Div className="prompt-text">{slides[activeStep - 1]?.prompt}</Div>}
                                            <Div className="result-text">{slides[activeStep - 1]?.response}</Div>
                                            <Div id="result_sources" className="content-text">{source}</Div>
                                        </Div> :
                                        <PowerRunning className="prev-slide" persona={persona}/>)
                                    }
                                    {
                                        slides[activeStep]?.response ?
                                        <Div className="result-box content-box main-slide">
                                            {(activeStep > 0 && !errorMessage) && <Div className="prompt-text">{slides[activeStep]?.prompt}</Div>}
                                            <Div className="result-text">
                                                {slides[activeStep]?.response}
                                            </Div>
                                            <Div id="result_sources" className="content-text">{source}</Div>
                                        </Div> :
                                        <PowerRunning className="main-slide" persona={persona}/>
                                        }
                                    {
                                        activeStep + 1 < slides.length && 
                                        (slides[activeStep + 1]?.response ?
                                        <Div className="result-box content-box next-slide">
                                            {(activeStep + 1 > 0 && !errorMessage) && <Div className="prompt-text">{slides[activeStep + 1]?.prompt}</Div>}
                                            <Div className="result-text">{slides[activeStep + 1]?.response}</Div>
                                            <Div id="result_sources" className="content-text">{source}</Div>
                                        </Div> :
                                        <PowerRunning className="next-slide" persona={persona}/>)
                                    }
                                </Div>
                                {attempts <= 3 && <MobileStepper
                                    className="carousel-stepper"
                                    variant="text"
                                    steps={slides.length}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{ bgcolor: 'transparent' }}
                                    nextButton={
                                        <Button
                                            size="small"
                                            onClick={handleNext}
                                            disabled={activeStep === slides.length - 1}
                                        >
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                            {theme.direction === 'rtl' ? (
                                                <KeyboardArrowRight />
                                            ) : (
                                                <KeyboardArrowLeft />
                                            )}
                                        </Button>
                                    }
                                />}
                            </Div>
                        </Div>
                    {(attempts < 4 || (slides[activeStep]?.response && !newErroMessage)) && 
                        <Div id="lb_refinement_input">
                            <FormControl fullWidth sx={{ m: 1 }} variant="outlined" id="outlined-adornment-control">
                                <OutlinedInput
                                    size="small"
                                    id="outlined-adornment-prompt"
                                    value={newPrompt}
                                    onChange={handlePromptChange}
                                    onKeyDown={handleKeyPress}
                                    autoComplete='off'
                                    disabled={!attempts || isLoading || !result || !!errorMessage}
                                    placeholder="How would you like to refine your results?"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                className="lb_action"
                                                id="lb_submit"
                                                size="small"
                                                color="secondary"
                                                onClick={handleSubmit}
                                                disabled={!attempts || isLoading || !result || !!errorMessage}
                                            >
                                                <Send />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText className="refinements-remaining-counter">{`${attempts} remaining`}</FormHelperText>
                            </FormControl>
                        </Div>
                    }
                </Div>
                <Div id="lb_actions">
                    <Div className="lb_left-buttons">
                        <Button
                            onClick={() => clearResult()}
                            startIcon={<ChevronLeft size="small" />}
                            size="small"
                        >
                            Back
                        </Button>
                    </Div>
                    <Div className="lb_right-buttons"></Div>
                </Div>
            </Div>
            <Div id="lb_footer_row">
                <Div id="lb_tip-below">
                    Results may vary. Please confirm accuracy before sharing or
                    decision-making.
                </Div>
            </Div>
        </Div>
    );
}
