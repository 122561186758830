const WISPY_API_URL = process.env.REACT_APP_WISPY_API_SERVER_URL;

const POWERS_CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds
const POWERS_CACHE_KEY = "powers-cache";

const ACTIVE_POWERS_CACHE_TIMEOUT = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const ACTIVE_POWERS_CACHE_KEY = "activePowers-cache";

/**
 * Fetches the list of powers from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
let powersCache = null;
let lastCacheTime = 0;

export const fetchPowers = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchPowers: invalid accessToken");
    }

    // Return cached powers if available and not stale
    const now = Date.now();
    if (powersCache && now - lastCacheTime < POWERS_CACHE_TIMEOUT) {
        return powersCache;
    }

    // Check if powers are available in localStorage
    const cachedPowers = localStorage.getItem(POWERS_CACHE_KEY);
    if (cachedPowers) {
        const parsedPowers = JSON.parse(cachedPowers);
        if (now - parsedPowers.timestamp < POWERS_CACHE_TIMEOUT) {
            powersCache = parsedPowers.data;
            lastCacheTime = parsedPowers.timestamp;
            return powersCache;
        }
    }

    try {
        // Only get public and approved powers here so we don't show creator's own private powers in the gallery
        const response = await fetch(
            `${WISPY_API_URL}/powers?public=true&approved=true`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPowers: ${error}`);
        }

        const data = await response.json();

        // Update cache and last cache time
        powersCache = data;
        lastCacheTime = now;

        // Update localStorage with new cache
        localStorage.setItem(
            POWERS_CACHE_KEY,
            JSON.stringify({
                timestamp: lastCacheTime,
                data: powersCache,
            })
        );

        return data;
    } catch (error) {
        console.error("Error fetching powers:", error);
        throw error;
    }
};

/**
 * Fetches the list of active powers for user from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchActivePowers = async (accessToken, forceRefresh = false) => {
    if (!accessToken) {
        throw new Error("fetchActivePowers: invalid accessToken");
    }

    const getCachedActivePowers = () => {
        const cachedPowers = localStorage.getItem(ACTIVE_POWERS_CACHE_KEY);
        if (cachedPowers) {
            const { powers, timestamp } = JSON.parse(cachedPowers);
            if (Date.now() - timestamp < ACTIVE_POWERS_CACHE_TIMEOUT) {
                return powers;
            } else {
                localStorage.removeItem(ACTIVE_POWERS_CACHE_KEY);
            }
        }
        return null;
    };

    // If forceRefresh is not set, try to get the cached active powers
    if (!forceRefresh) {
        const powersFromCache = getCachedActivePowers();
        if (powersFromCache) {
            return powersFromCache;
        }
    }

    // If there's no cache or if forceRefresh is true, fetch from the server
    try {
        const response = await fetch(
            `${WISPY_API_URL}/powers?inventoryOnly=true`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchActivePowers: ${error}`);
        }

        const data = await response.json();
        
        // Cache the fetched active powers
        localStorage.setItem(
            ACTIVE_POWERS_CACHE_KEY,
            JSON.stringify({ powers: data, timestamp: Date.now() })
        );

        return data;
    } catch (error) {
        console.error("Error fetching activePowers:", error);
        throw error;
    }
};


/**
 * Fetches the list of official powers from the API server that are active/inventory
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchDefaultPowers = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchDefaultPowers: invalid accessToken");
    }

    try {
        const response = await fetch(
            `${WISPY_API_URL}/powers?inventoryOnly=true&creator=officialTMC&fields=_id&fields=name&fields=iconID`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchDefaultPowers: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching default powers:", error);
        throw error;
    }
};

/**
 * Fetches the list of powers created by this user from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchCreatedPowers = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchCreatedPowers: invalid accessToken");
    }

    try {
        const response = await fetch(`${WISPY_API_URL}/powers?&mineOnly=true`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchCreatedPowers: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching created powers:", error);
        throw error;
    }
};

/**
 * Fetches a single power by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the power to be fetched
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const fetchPowerById = async (accessToken, id) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/powers/${id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPowerById: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error fetching power with id ${id}:`, error);
        throw error;
    }
};

/**
 * Deletes a power by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the power to be deleted
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const deleteCreatedPower = async (accessToken, id) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/powers/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`deleteCreatedPower: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error deleting power with id ${id}:`, error);
        throw error;
    }
};


export const sendPowerFeedback = async (accessToken, payload) => {
    const { powerID, direction, feedback, result_number, total_refinements } = payload;
    let targetURL = `${WISPY_API_URL}/powers/${powerID}/feedback`;
    let response = await fetch(
        targetURL, {
            method: "POST",
            body: JSON.stringify({
                direction: direction,
                feedback: feedback,
                result_number: result_number,
                total_refinements: total_refinements,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
    if (response.status !== 200) {
        throw new Error("Unable to send feedback");
    }
}