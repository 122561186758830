export const ACCOUNTS_API_URL = process.env.REACT_APP_ACCOUNTS_API_SERVER_URL;

export const addToActivePowers = async (accessToken, power_id) => {
    if (!accessToken) {
        throw new Error("addToActivePowers: invalid accessToken");
    }
    
    if (!power_id) {
        throw new Error("addToActivePowers: invalid power_id");
    }

    try {
        const response = await fetch(`${ACCOUNTS_API_URL}/powerInventory`, {
            method: "PUT",
            body: JSON.stringify({
                id: power_id,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("addToActivePowers: resource unavailable, please try again later");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error adding to activePowers:", error);
        throw error;
    }
};

export const removeFromActivePowers = async (accessToken, power_id) => {
    if (!accessToken) {
        throw new Error("removeFromActivePowers: invalid accessToken");
    }
    
    if (!power_id) {
        throw new Error("removeFromActivePowers: invalid power_id");
    }

    try {
        console.log("DELETE /powerInventory for "+power_id);
        const response = await fetch(`${ACCOUNTS_API_URL}/powerInventory`, {
            method: "DELETE",
            body: JSON.stringify({
                id: power_id,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("removeFromActivePowers: resource unavailable, please try again later");
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error removing from activePowers:", error);
        throw error;
    }
};

export const getEnergyUsage = async (accessToken) => {
    if (!accessToken) {
        throw new Error("getEnergyUsage: invalid accessToken");
    }

    // Check if data exists in localStorage and if it's still valid
    const cachedData = localStorage.getItem('energyUsageData');
    const cacheTimestamp = localStorage.getItem('energyUsageTimestamp');
    const currentTime = Date.now();
    const oneMinute = 60000;  // 60 seconds * 1000 milliseconds

    if (cachedData && cacheTimestamp && (currentTime - cacheTimestamp <= oneMinute)) {
        return JSON.parse(cachedData);
    }

    try {
        const response = await fetch(`${ACCOUNTS_API_URL}/energyUsage`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("getEnergyUsage: resource unavailable, please try again later");
        }
        const data = await response.json();

        // Save data and timestamp to localStorage
        localStorage.setItem('energyUsageData', JSON.stringify(data));
        localStorage.setItem('energyUsageTimestamp', currentTime.toString());

        return data;
    } catch (error) {
        console.error("Error getting energy usage for userID:", error);
        throw error;
    }
};

export const getHandle = async (accessToken, user_id) => {
    if (!accessToken) {
        throw new Error("getHandle: invalid accessToken");
    }
    
    if (!user_id) {
        throw new Error("addToInventory: invalid user_id");
    }

    try {
        console.log("PUT /getHandle for "+user_id);
        const response = await fetch(`${ACCOUNTS_API_URL}/getHandle`, {
            method: "PUT",
            body: JSON.stringify({
                userID: user_id,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("getHandle: resource unavailable, please try again later");
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error getting handle for userID:", error);
        throw error;
    }
};

export const getPowerInventory = async (accessToken) => {
    if (!accessToken) {
        throw new Error("getHandle: invalid accessToken");
    }

    try {
        console.log("GET /powerInventory");
        const response = await fetch(`${ACCOUNTS_API_URL}/powerInventory`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("getInventory: resource unavailable, please try again later");
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error in getInventory:", error);
        throw error;
    }
};
export const getStripePortalUrl = async (accessToken) => {
    const fallbackUrl = "https://billing.stripe.com/p/login/eVabLw3okbxo0TedQQ";
    
    try {
        if (!accessToken) {
            throw new Error("getStripePortalUrl: invalid accessToken");
        }
        console.log("GET /stripe-portal-link");
        const response = await fetch(`${ACCOUNTS_API_URL}/stripe-portal-link`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status !== 200) {
            throw new Error("getStripePortalUrl: resource unavailable, please try again later");
        }
        const data = await response.json();
        return data.url ?? fallbackUrl;
    } catch (error) {
        console.error("Error getting stripe portal url:", error);
        return fallbackUrl;
    }
}

export const getPersonaInventory = async (accessToken) => {
    if (!accessToken) {
        throw new Error("getPersonaInventory: invalid accessToken");
    }

    try {
        console.log("GET /getPersonaInventory");
        const response = await fetch(`${ACCOUNTS_API_URL}/personaInventory`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        console.log(response);
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("getPersonaInventory: resource unavailable, please try again later");
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error("Error getting Persona Inventory:", error);
        throw error;
    }
};

/**
 * Updates the users inventory of personas from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const applyPersona = async (accessToken, personaId) => {
    if (!accessToken) {
        throw new Error("applyPersona: invalid accessToken");
    }

    try {
        const response = await fetch(`${ACCOUNTS_API_URL}/personaInventory`,
            {
                method: "PUT",
                body: JSON.stringify({
                    id: personaId,
                    defaultPersona: personaId
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("applyPersona: resource unavailable, please try again later");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error adding to Persona Inventory:", error);
        throw error;
    }
};


/**
 * Delete the persona from the users inventory from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const removePersonaInventory = async (accessToken, personaId) => {
    if (!accessToken) {
        throw new Error("removePersonaInventory: invalid accessToken");
    }
    try {
        const response = await fetch(`${ACCOUNTS_API_URL}/personaInventory`,
            {
                method: "DELETE",
                body: JSON.stringify({
                    id: personaId,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("removePersonaInventory: resource unavailable, please try again later");
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error adding to Persona Inventory:", error);
        throw error;
    }
};

/**
 * Adds persona to the users inventory of personas from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const addPersonaToInventory = async (accessToken, personaId) => {
    if (!accessToken) {
        throw new Error("applyPersona: invalid accessToken");
    }

    try {
        const response = await fetch(`${ACCOUNTS_API_URL}/personaInventory`,
            {
                method: "PUT",
                body: JSON.stringify({
                    id: personaId,
                }),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("applyPersona: resource unavailable, please try again later");
        }
        const data = await response.json();
        console.log(data)
        return data;
    } catch (error) {
        console.error("Error adding to Persona Inventory:", error);
        throw error;
    }
};
