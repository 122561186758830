import React, { useState } from 'react';
import { Button, TextField, Dialog  , DialogContent, DialogActions } from '@mui/material';
import { useAccessToken } from "../../services/auth0";
import './feedback-dialog.css'
import { sendPowerFeedback } from '../../services/powers-api';

export const FeedbackDialog = (props) => {
  const accessToken = useAccessToken();
  const { open, feedbackDirection, setOpen, powerID, resultNumber, totalRefinements, handleFeedbackSubmitted } = props;
  const [inputValue, setInputValue] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    try {
      sendPowerFeedback(accessToken, {
        powerID, 
        direction: feedbackDirection, 
        feedback: {freeform:inputValue}, 
        result_number: resultNumber, 
        total_refinements: totalRefinements,
      })
      handleFeedbackSubmitted();
    } catch(error) {
    } finally {
      handleClose();
      setInputValue('');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} className='feedback-dialog' >
      <DialogContent sx={{ padding: '8px' }}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          value={inputValue}
          onChange={handleInputChange}
          placeholder={feedbackDirection > 0 ? "Thanks! Tell us more about how this power helped you." : "We're sorry to hear that. How could we improve this experience?"}
          sx={{ width: '320px' }}
        />
      </DialogContent>
      <DialogActions>
          <Button onClick={handleSubmit} variant="outlined" fullWidth>
            Submit Feedback
        </Button> 
      </DialogActions>
    </Dialog>
  );
};
