const WISPY_API_URL = process.env.REACT_APP_WISPY_API_SERVER_URL;

export const invokePower = async (accessToken, prompt, power_id, personaID = null) => {
    if (!accessToken) {
        throw new Error("invokePower: invalid accessToken");
    }
    
    if (!power_id) {
        throw new Error("invokePower: invalid power_id");
    }

    try {
        console.log("POST /summon for " + power_id);
        const response = await fetch(`${WISPY_API_URL}/summon`, {
            method: "POST",
            body: JSON.stringify({
                powerID: power_id,
                prompt: prompt,
                personaID: personaID,
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        });
        
        const data = await response.json();
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("invokePower: resource unavailable, please try again later");
        } else if (response.status !== 200) {
            throw new Error(data.error)
        }
        return data;
    } catch (error) {
        console.error("Error in invokePower:", error);
        throw error;
    }
};

export const powerRefinement = async (accessToken, powerID, powerData, messages, personaID = null ) => {
    if (!accessToken) {
        throw new Error("invokePower: invalid accessToken");
    }
    
    if (!powerID) {
        throw new Error("invokePower: invalid power_id");
    }

    try {
        const response = await fetch(`${WISPY_API_URL}/summon`, {
            method: "POST",
            body: JSON.stringify({
                powerID: powerID,
                abilityConfig: powerData?.abilityConfig,
                customized: !!powerData,
                promptMulti: messages,
                personaID: personaID,
                role: "user",
            }),
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
        })

        const data = await response.json();
        if (response.status === 404 || (response.status >= 500 && response.status < 600)) {
            throw new Error("invokePower: resource unavailable, please try again later");
        }  else if (response.status !== 200) {
            throw new Error(data.error)
        }
        return data;
    } catch (error) {
        console.error("Error in invokePower:", error);
        throw error;
    }
}