import { Container, Button, Box, Stack, Link } from '@mui/material';
import { useAuthActions } from "../services/auth0";

import { Wispy } from "../components/wispy/wispy"

import '../styles/LandingPage.css'

import TMCLogo from "../assets/brand/tmc-logo_outline.svg"
import WordMark from '../assets/brand/Wordmark.svg'

const WISPY_WEB_URL = process.env.REACT_APP_WISPY_WEB_URL;

export const LandingPage = () => {
  const { signIn } = useAuthActions();

  return (
    <Container maxWidth="sm" display="flex" flexDirection="column" p={6} id='landing-page'>
        <Stack alignItems="center" justifyContent="center" height="100vh" spacing={6}>
            <img className="wordmark" src={WordMark} alt="Wordmark" />
            <Wispy id="landing-page-wispy"/>
            <Stack alignItems="center" justifyContent="center" width="60vw" spacing={2}>
                <Button variant="contained" color="primary" onClick={signIn} fullWidth>Sign In</Button>
                <Button variant="outlined" color="primary" onClick={() => window.open(WISPY_WEB_URL, '_blank')} fullWidth>Learn More</Button>
            </Stack>
            <Box className="footer">
                <Link className="footer-logo" href="https://technicalmagic.ai/"><img className="ttmc-footer" alt="Wispy" src={TMCLogo} /></Link>
            </Box>
        </Stack>
    </Container>
  );
};
