import { ReactSVG } from 'react-svg';
import ListUL from "../assets/powers/list-ul.svg";
import Play from "../assets/powers/play-circle.svg";
import Hourglass from "../assets/powers/hourglass.svg";
import Restaurant from "../assets/powers/restaurant.svg";
import ShoppingBag from "../assets/powers/shopping-bag.svg";
import Lightbulb from "../assets/powers/lightbulb-alt.svg";
import Shield from "../assets/powers/shield.svg";
import Trophy from "../assets/powers/trophy.svg";
import Envelope from "../assets/powers/envelope-edit.svg";
import Calendar from "../assets/powers/calendar-alt.svg";
import FileEdit from "../assets/powers/file-edit-alt.svg";
import Star from "../assets/powers/star.svg";
import Laughing from "../assets/powers/laughing.svg";
import BalanceScale from "../assets/powers/balance-scale.svg";
import Microscope from "../assets/powers/microscope.svg";
import Heart from "../assets/powers/heart.svg";

export function lb_getPowerIconById(iconID) {
    const icons = {
        "list-ul" : ListUL,
        "play-circle" : Play,
        "hourglass" : Hourglass,
        "restaurant" : Restaurant,
        "shopping-bag" : ShoppingBag,
        "lightbulb-alt" : Lightbulb,
        "shield" : Shield,
        "trophy" : Trophy,
        "envelope-edit" : Envelope,
        "calendar-alt" : Calendar,
        "file-edit-alt" : FileEdit,
        "star" : Star,
        "laughing" : Laughing,
        "balance-scale" : BalanceScale,
        "microscope" : Microscope,
        "heart" : Heart
    };
    if (Object.keys(icons).includes(iconID)) {
        let src = icons[iconID];
        return (
            <ReactSVG className="lb_glyph" src={src} alt={iconID} />
        );
    } else {
        return (
            <ReactSVG className="lb_glyph" src={ListUL} alt={iconID} />
        );
    }
}
