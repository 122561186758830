
const WISPY_API_URL = process.env.REACT_APP_WISPY_API_SERVER_URL;

const PERSONAS_CACHE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds
const PERSONAS_CACHE_KEY = "personas-cache";

/**
 * Fetches the list of personas from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
let personasCache = null;
let lastCacheTime = 0;

export const fetchPersonas = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchPersonas: invalid accessToken");
    }

    // Return cached personas if available and not stale
    const now = Date.now();
    if (personasCache && now - lastCacheTime < PERSONAS_CACHE_TIMEOUT) {
        return personasCache;
    }

    // Check if personas are available in localStorage
    const cachedPersonas = localStorage.getItem(PERSONAS_CACHE_KEY);
    if (cachedPersonas) {
        const parsedPersonas = JSON.parse(cachedPersonas);
        if (now - parsedPersonas.timestamp < PERSONAS_CACHE_TIMEOUT) {
            personasCache = parsedPersonas.data;
            lastCacheTime = parsedPersonas.timestamp;
            return personasCache;
        }
    }

    try {
        // Only get public and approved personas here so we don't show creator's own private personas in the gallery
        const response = await fetch(
            `${WISPY_API_URL}/personas?public=true&approved=true`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPersonas: ${error}`);
        }

        const data = await response.json();

        // Update cache and last cache time
        personasCache = data;
        lastCacheTime = now;

        // Update localStorage with new cache
        localStorage.setItem(
            PERSONAS_CACHE_KEY,
            JSON.stringify({
                timestamp: lastCacheTime,
                data: personasCache,
            })
        );

        return data;
    } catch (error) {
        console.error("Error fetching personas:", error);
        throw error;
    }
};

/**
 * Fetches the list of active personas for user from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchInventoryPersonas = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchInventoryPersonas: invalid accessToken");
    }

    try {
        const response = await fetch(
            `${WISPY_API_URL}/personas?inventoryOnly=true`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchInventoryPersonas: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetchInventoryPersonas:", error);
        throw error;
    }
};

/**
 * Fetches the list of created personas by the user from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */

export const fetchCreatedPersonas = async (accessToken) => {
    if (!accessToken) {
        throw new Error("fetchCreatedPersonas: invalid accessToken");
    }

    try {
        const response = await fetch(
            `${WISPY_API_URL}/personas?mineOnly=true`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchCreatedPersonas: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetchCreatedPersonas:", error);
        throw error;
    }
};

/**
 * Fetches a single persona by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the persona to be fetched
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const fetchPersonaById = async (accessToken, id) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/personas/${id}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`fetchPersonaById: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error fetching persona with id ${id}:`, error);
        throw error;
    }
};

/**
 * Posts a persona to the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {object} payload - Create persona payload
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const postPersona = async (accessToken, payload) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/personas`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`postPersona: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error posting persona:`, error);
        throw error;
    }
};

/**
 * Patches a persona by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the persona to be patched
 * @param {object} payload - patch persona payload
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const patchPersona= async (accessToken, id, payload) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/personas/${id}`, {
            method: "PATCH",
            body: JSON.stringify(payload),
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`patchPersona: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error patching persona with id ${id}:`, error);
        throw error;
    }
};

/**
 * Remixes a persona by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the persona to be remixed
 * @param {object} payload - remix persona payload
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const remixPersona= async (accessToken, id, payload) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/personas/${id}/remix`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`remixPersona: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error patching persona with id ${id}:`, error);
        throw error;
    }
};

/**
 * Deletes a persona by ID from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {string} id - The ID of the persona to be deleted
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const deletePersona = async (accessToken, id) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/personas/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`deletePersona: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error deleting persona with id ${id}:`, error);
        throw error;
    }
};

/**
 * Previews a persona from the API server
 * @param {string} accessToken - The access token to be used for authorization
 * @param {object} payload - object containing personaID, prompt, name
 * @returns {Promise<Object>} A Promise that resolves to the data from the server
 * @throws {Error} Throws an error if there is a server error
 */
export const previewPersona = async (accessToken, payload) => {
    try {
        const response = await fetch(`${WISPY_API_URL}/personas/preview`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.status !== 200) {
            let error = await response.text();
            throw new Error(`previewPersona: ${error}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error previewing persona:`, error);
        throw error;
    }
};