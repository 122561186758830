import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const PageLoader = () => {
  return (
    <Box sx={{ 
        width: '100%', 
        height: '100vh',
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center' 
    }}>
      <CircularProgress />
    </Box>
  );
};