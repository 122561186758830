import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AUTH0_CACHE_KEY = "accessToken-cache";
export const AUTH0_CACHE_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
export const SPRITE_URL = process.env.REACT_APP_SPRITE_URL;

export const useAuthActions = () => {
  const { logout, user, loginWithRedirect } = useAuth0();

  const signIn = async () => {
    await loginWithRedirect({
    appState: {
      returnTo: "/home",
    },
    authorizationParams: {
      prompt: "login",
    },
    });
  };

  const signOut = () => {
    const redirectUri = `${SPRITE_URL}`;
    localStorage.removeItem(AUTH0_CACHE_KEY);
    logout({ 
      logoutParams: {
        returnTo: redirectUri,
       },
   });
  };

  const getUser = () => {
    return user;
  };

  return { signIn, signOut, getUser };
};


/**
 * A custom hook to get the access token from Auth0
 * @returns {string} The access token
 */
export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

 useEffect(() => {
   const getCachedToken = () => {
     if (!accessToken) {
       const cachedToken = localStorage.getItem(AUTH0_CACHE_KEY);
       if (cachedToken) {
         const { token, timestamp } = JSON.parse(cachedToken);
         if (Date.now() - timestamp < AUTH0_CACHE_TIMEOUT) {
           setAccessToken(token);
         } else {
           localStorage.removeItem(AUTH0_CACHE_KEY);
           setAccessToken(null);
         }
       }
     }
   };

   const refreshAccessToken = async () => {
     if (!accessToken) {
       try {
         const newToken = await getAccessTokenSilently({
           audience: process.env.REACT_APP_AUTH0_AUDIENCE
         });
         if (newToken) {
           setAccessToken(newToken);
           localStorage.setItem(
             AUTH0_CACHE_KEY,
             JSON.stringify({ token: newToken, timestamp: Date.now() })
           );
         }
        } catch (error) {
           console.log(error);
                 }
      }
    };

   getCachedToken();
   refreshAccessToken(); // only if necessary
 }, [accessToken, getAccessTokenSilently]);

  return accessToken;
};

