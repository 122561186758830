import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// When we receive a share from mobile device Web Share API we parse the query string and redirect to the home page with the query string params, if they exist
export const ShareHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const title = searchParams.get('title');
  const url = searchParams.get('text') || searchParams.get('url'); // text is used by Android, url is used by iOS

  let queryString = `?title=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`;
  console.log(queryString);

  useEffect(() => {
    if (title && url) {
      navigate(`/home${queryString}`);
    } else {
      navigate('/home');
    }
  }, [navigate, title, url, queryString]);
};