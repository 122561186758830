import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PageLoader } from "./page-loader";

import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";

export const SPRITE_URL = process.env.REACT_APP_SPRITE_URL;

export const Auth0Callback = () => {
    const [searchParams] = useSearchParams();
    const error = searchParams.get("error");
    const error_description = searchParams.get("error_description");

    // Notify an external script or extension after a delay, if needed.
    useEffect(() => {
        if (!error) {
            const timeoutId = setTimeout(() => {
                window.postMessage({ type: "wispy-signed-in" }, "*");
            }, 1000);

            // Clear timeout on component unmount
            return () => clearTimeout(timeoutId);
        }
    }, [error]);

    // Render error UI if authentication failed
    if (error) {
        return (
            <div>
                    <WarningIcon />
                    {error_description}
                    <Button onClick={() => window.location.href = `${SPRITE_URL}`}>Return to Sign In</Button>
            </div>
        );
    }

    // Loading UI while waiting for authentication to finalize
    return <PageLoader />;
};
