import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Auth0Callback } from "./components/auth0-callback";
import { ShareHandler } from "./components/share-handler";
import { AuthenticationGuard } from "./components/authentication-guard";

import { LandingPage } from "./pages/LandingPage";
import { Agent } from "./pages/Agent";
import { AgentPowerInvocation } from './pages/Invoke';

function useDynamicVH() {
  useEffect(() => {
    function setVHVariable() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setVHVariable();
    window.addEventListener('resize', setVHVariable);
    window.addEventListener('orientationchange', setVHVariable);

    return () => {
      window.removeEventListener('resize', setVHVariable);
      window.removeEventListener('orientationchange', setVHVariable);
    };
  }, []);
}

function App() {
  
  useDynamicVH();

  return (
   <Routes>
      <Route path="/auth0-callback" element={<Auth0Callback />} />
      <Route path="/share-handler" element={<AuthenticationGuard component={ShareHandler} />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={<AuthenticationGuard component={Agent} />} />
      <Route path="/invoke/:id" element={<AuthenticationGuard component={AgentPowerInvocation} />} />    
   </Routes>
  );
}

export default App;