import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Mustache from "mustache";
import { Button, Box as Div, Checkbox, FormControlLabel, Link } from '@mui/material';
import { PlayCircleOutline, Close, ChevronLeft } from '@mui/icons-material/';
import { useAccessToken } from "../services/auth0";
import { fetchPowerById } from "../services/powers-api";
import { invokePower } from "../services/summon-api";
import { lb_getPowerIconById } from "../services/power-utils";
import { PowerResult } from '../components/power-result/power-result';
import { fetchInventoryPersonas } from "../services/personas-api";
import { PageLoader } from "../components/page-loader";
import "../styles/agent.css";

export const WISPY_WEB_URL = process.env.REACT_APP_WISPY_WEB_URL;

export const AgentPowerInvocation = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isResultLoading, setIsResultLoading] = useState(false);
    const [usePersona, setUsePersona] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [power, setPower] = useState(null);
    const [result, setResult] = useState(null);
    const [source, setSource] = useState('')
    const [persona, setPersona] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const accessToken = useAccessToken();
    const containerRef = useRef(null);
    const [isValidURL, setIsValidURL] = useState(false);

    const location = useLocation();
    let locationParams = new URLSearchParams(location.search);
    let focusPageURL = locationParams.get('url');

    useEffect(() => {
        try {
            new URL(focusPageURL);
            setIsValidURL(true);
        } catch {
            setIsValidURL(false);
        }
    }, [focusPageURL])

    // fetch persona
    useEffect(() => {
        const fetchUserPersona = async () => {
            if (accessToken) {
                try {
                    const data = await fetchInventoryPersonas(accessToken);
                    const [selectedPersona] = data.filter((persona) => persona?._isUsersDefault)
                    setPersona(selectedPersona)
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchUserPersona();
    }, [accessToken]);

    // fetch power
    useEffect(() => {
        const getPower = async (id) => {
            if (accessToken && id) {
                setIsLoading(true);
                try {
                    let data = await fetchPowerById(accessToken, id);
                    setPower(data);
                } catch (error) {
                    console.error(error);
                }
                setIsLoading(false);
            }
        }
        getPower(id);
    }, [accessToken, id]);

    const loadPower = (power) => {
        let msg = { modalOptions: power?.userInputs, userInput: [] };
        // TODO: Iterate through contentExtractors and add options for each
        // or disable the Power for this interface.
        if (power.contentExtractors.length !== 0) {
            const contentOption = {
                key: 'content',
                type: 'long_text',
                label: 'Content to invoke power on',
                placeholder: 'Provide URL of web page, or text content you\'d like to invoke this power on.',
                required: true
            }
            msg.modalOptions.push(contentOption);
        }
        let optionsContainer = containerRef.current;

        if (optionsContainer && msg.modalOptions && !optionsContainer.getAttribute("data-populated")) {
            let modalOptionsDiv = document.getElementById("lb_modal_options");
            if (modalOptionsDiv)
                modalOptionsDiv.style.display = "block";
            optionsContainer.style.display = "block";

            // Iterate options and create elements;
            msg.modalOptions.forEach((userInput) => {
                let fixedValueFromLocation = (userInput.key === "content" && focusPageURL && isValidURL);

                let optionRow = document.createElement("div");
                optionRow.classList.add("row");
                let labelDiv = document.createElement("div");
                let label = document.createElement("label");
                labelDiv.classList.add("lb_label");
                let controlDiv = document.createElement("div");
                controlDiv.classList.add("lb_control");
                controlDiv.classList.add("lb_" + userInput.type);
                optionRow.appendChild(labelDiv);
                labelDiv.appendChild(label);
                optionRow.appendChild(controlDiv);
                if (userInput.hint && !fixedValueFromLocation) {
                    let hintDiv = document.createElement("div");
                    hintDiv.classList.add("lb_hint");
                    optionRow.appendChild(hintDiv);
                    hintDiv.textContent = userInput.hint;
                }
                if (userInput.label) {
                    label.textContent = userInput.label;
                } else {
                    label.textContent = userInput.key;
                }
                if (userInput.required) {
                    // add a "Required" indicator to the right of label
                    let requiredDiv = document.createElement("span");
                    requiredDiv.textContent = " *";
                    requiredDiv.classList.add("lb_required");
                    label.appendChild(requiredDiv);
                }

                // Handle focus page: hide Content element
                if (fixedValueFromLocation) {
                    let urlDisplayDiv = document.createElement("div");
                    urlDisplayDiv.classList.add("lb_focus_url_div");
                    urlDisplayDiv.id = `lb_modal_${userInput.key}`;
                    label.htmlFor = urlDisplayDiv.id;
                    controlDiv.appendChild(urlDisplayDiv);
                    urlDisplayDiv.textContent = focusPageURL;
                } else if (userInput.type === "short_text") {
                    let input = document.createElement("input");
                    input.type = "text";
                    input.id = `lb_modal_${userInput.key}`;
                    input.classList.add("lb_short_text");
                    label.htmlFor = input.id;
                    controlDiv.appendChild(input);
                    if (msg.userInputs?.[userInput.key]) {
                        if (typeof msg.userInputs[userInput.key] === "string") {
                            input.value = msg.userInputs[userInput.key];
                        }
                    } else
                        if (userInput.default) {
                            if (typeof userInput.default === "string") {
                                input.value = userInput.default;
                            }
                        }
                    if (userInput.placeholder) {
                        if (typeof userInput.placeholder === "string") {
                            input.placeholder = userInput.placeholder;
                        }
                    }
                } else if (userInput.type === "long_text") {
                    let input = document.createElement("textarea");
                    input.classList.add("lb_long_text");
                    input.id = `lb_modal_${userInput.key}`;
                    label.htmlFor = input.id;
                    controlDiv.appendChild(input);
                    if (msg.userInputs?.[userInput.key]) {
                        if (typeof msg.userInputs[userInput.key] === "string") {
                            input.value = msg.userInputs[userInput.key];
                        }
                    } else
                        if (userInput.default) {
                            if (typeof userInput.default === "string") {
                                input.value = userInput.default;
                            }
                        }
                    if (userInput.placeholder) {
                        if (typeof userInput.placeholder === "string") {
                            input.placeholder = userInput.placeholder;
                        }
                    }
                } else if (userInput.type === "checkbox") {
                    userInput.options.forEach((radioOption, index) => {
                        let input = document.createElement("input");
                        input.type = "checkbox";
                        input.name = userInput.key;
                        input.id = `lb_modal_${userInput.key}_${index}`;
                        input.value = radioOption.value;
                        if (msg.userInputs?.[userInput.key] &&
                            Array.isArray(msg.userInputs[userInput.key]) &&
                            msg.userInputs[userInput.key].includes(radioOption.value)) {
                            input.checked = true;
                        } else
                            if (userInput.default &&
                                Array.isArray(userInput.default) &&
                                userInput.default.includes(radioOption.value)) {
                                input.checked = true;
                            }
                        controlDiv.appendChild(input);
                        let sublabel = document.createElement("label");
                        sublabel.htmlFor = input.id;
                        sublabel.textContent = radioOption.label;
                        controlDiv.appendChild(sublabel);
                    });
                } else if (userInput.type === "radio") {
                    userInput.options.forEach((radioOption, index) => {
                        let input = document.createElement("input");
                        input.type = "radio";
                        input.name = userInput.key;
                        input.id = `lb_modal_${userInput.key}_${index}`;
                        input.value = radioOption.value;
                        if (msg.userInputs?.[userInput.key] &&
                            msg.userInputs[userInput.key] === radioOption.value) {
                            input.checked = true;
                        } else
                            if (userInput.default &&
                                userInput.default === radioOption.value) {
                                input.checked = true;
                            }
                        controlDiv.appendChild(input);
                        let sublabel = document.createElement("label");
                        sublabel.htmlFor = input.id;
                        sublabel.textContent = radioOption.label;
                        controlDiv.appendChild(sublabel);
                    });
                } else if (userInput.type === "select") {
                    let select = document.createElement("select");
                    select.id = `lb_modal_${userInput.key}`;
                    label.htmlFor = select.id;
                    userInput.options.forEach((selectOption) => {
                        let option = document.createElement("option");
                        option.value = selectOption.value;
                        option.textContent = selectOption.label;
                        if (msg.userInputs?.[userInput.key] &&
                            msg.userInputs[userInput.key] === selectOption.value) {
                            option.selected = true;
                        } else
                            if (userInput.default &&
                                userInput.default === selectOption.value) {
                                option.selected = true;
                            }
                        select.appendChild(option);
                    });
                    if (!userInput.default) {
                        // ensure nothing is selected to start
                        select.selectedIndex = -1;
                    }
                    controlDiv.appendChild(select);
                } else {
                    console.error("Error: unknown userInput type: ", userInput.type);
                }
                optionsContainer.appendChild(optionRow);
                optionsContainer.setAttribute("data-populated", "true");
            });
        }
    }

    useEffect(() => {
        if (power && containerRef) {
            loadPower(power);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [power]);

    async function handleInvokePower() {
        if (power) {
            let msg = { modalOptions: power?.userInputs, userInput: [] };
            let userInputValues = {};
            let missingRequired = false;
            let hasContentFromURL;
            // extract results from modal elements
            msg.modalOptions.forEach((userInput) => {

                // Handle special handling of content key
                if (userInput.key === "content" && focusPageURL && isValidURL) {
                    hasContentFromURL = true;
                    return;
                }

                // XXX should we block the reserved name "content" here?
                if (userInput.type === "short_text" || userInput.type === "long_text" || userInput.type === "select") {
                    let input = document.getElementById(`lb_modal_${userInput.key}`);
                    userInputValues[userInput.key] = input.value;
                } else if (userInput.type === "checkbox") { // combine all checked into list
                    let values = [];
                    if (userInput.options) {
                        userInput.options.forEach((checkOption, index) => {
                            let input = document.getElementById(`lb_modal_${userInput.key}_${index}`);
                            if (input.checked) {
                                values.push(checkOption.value);
                            }
                        });
                    }
                    userInputValues[userInput.key] = values;
                } else if (userInput.type === "radio") { // only one value is checked, if any
                    let value = null;
                    if (userInput.options) {
                        userInput.options.forEach((checkOption, index) => {
                            if (value) return;
                            let input = document.getElementById(`lb_modal_${userInput.key}_${index}`);
                            if (input.checked) {
                                value = checkOption.value;
                            }
                        });
                    }
                    userInputValues[userInput.key] = value;
                }
                if (userInput.required && !userInputValues[userInput.key]) {
                    missingRequired = true;
                }
            });
            let missingRequiredDiv = document.getElementById("lb_modal_options_missing_required");
            missingRequiredDiv.style.display = "none";
            if (missingRequired) {
                missingRequiredDiv.style.display = "block";
                return;
            }

            let dataMap = {};
            for (let inputKey in userInputValues) {
                dataMap[inputKey] = userInputValues[inputKey];
            }

            // TODO: Support for extractedData and/or integrate with and use power.mjs from copilot
            if (hasContentFromURL) {
                let extractorResult = await fetch("https://cex.technicalmagic.ai/content", {
                    method: "POST",
                    headers: {
                        "Content-type": "application/json"
                    },
                    body: JSON.stringify({
                        url: focusPageURL
                    })
                });
                let extractionSucceeded = false;
                if (extractorResult && extractorResult.status === 200) {
                    let extractorResultBody = await extractorResult.json();
                    console.log("Got extractor result", extractorResultBody);
                    if (extractorResultBody && extractorResultBody) {
                        if (extractorResultBody.output) {
                            setSource(extractorResultBody?.output?.href)
                            if (extractorResultBody.output.content) {
                                if (Array.isArray(extractorResultBody.output.content)) {
                                    dataMap["content"] = extractorResultBody.output.content[0];
                                }
                                extractionSucceeded = true;
                            }
                        }
                    }
                }
                if (!extractionSucceeded) {
                    setResult({ "error": "Sorry, the content you requested couldn't be loaded right now." });
                    return;
                }
            }

            let prompt = Mustache.render(power?.promptTemplate, dataMap);
            setIsResultLoading(true);
            try {
                let result;
                if (usePersona) {
                    result = await invokePower(accessToken, prompt, power._id, persona._id);
                } else {
                    result = await invokePower(accessToken, prompt, power._id);
                }

                if (result) {
                    console.log({ result });
                    setResult(result);
                }
            } catch (error) {
                setErrorMessage(error.message);
            } finally {
                setIsResultLoading(false);
            }
        }
    };

    const clearResult = () => {
        setResult(null);
        setErrorMessage('');
        navigate(`/invoke/${power._id}`)
    }

    if (isLoading) {
        return <Div className="lb_power_loading"><PageLoader /></Div>
    }

    if (!power) {
        return <Div>No power found for {id}</Div>
    }

    const prompt = Mustache.render(power?.promptTemplate, {});
    return (
        <>
            {(result || isResultLoading || errorMessage) &&
                <PowerResult
                    errorMessage={errorMessage}
                    power={power}
                    result={result}
                    prompt={prompt}
                    source={source}
                    clearResult={clearResult}
                    persona={persona}
                    usePersona={usePersona}
                />}
            <Div className="lb_content-layout" style={{ display: (result || isResultLoading || errorMessage) ? 'none' : 'block' }}>
                <Div id="lb_power-invocation">
                    <Div id="lb_modal_close">
                        <Close className="lb_close" onClick={() => navigate('/home')} />
                    </Div>
                    <Div id="lb_power_info_box" className="lb_content_box">
                        <Div id="lb_power_details_wrapper">
                            <Div id="lb_power_icon" className="lb_glyph">{lb_getPowerIconById(power?.iconID)}</Div>
                            <Div id="lb_power_details">
                                <Div id="lb_power_name">{power?.name?.["en-US"]}</Div>
                                <Div id="lb_power_creator">{power?._creatorHandle}</Div>
                            </Div>
                            <Div id="lb_power_description">
                                <Div id="lb_power_description_text">
                                    {power?.description?.["en-US"]}
                                </Div>
                                <Div id="lb_power_detail_link">
                                    <Link
                                        className="detail-link"
                                        target="_blank"
                                        href={`${WISPY_WEB_URL}/powers/${power?._id}`}
                                    >
                                        View details
                                    </Link>
                                </Div>
                            </Div>
                        </Div>
                        <Div id="lb_modal_options">
                            <Div id="lb_modal_options_container" ref={containerRef}></Div>
                        </Div>
                        <Div id="lb_power_result">
                        </Div>
                    </Div>
                    <Div id="lb_modal_options_missing_required" className="lb_missing-required">
                        <Div>Required fields are missing.</Div>
                    </Div>
                    <Div id="lb_footer">
                        <Div id="lb_checkbox_row">
                            <FormControlLabel
                                id="persona_checkbox"
                                control={
                                    <Checkbox checked={usePersona}
                                        onChange={() => setUsePersona(!usePersona)}
                                    />
                                }
                                label={
                                    persona ?
                                        `Use ${persona?.name['en-US']} persona` :
                                        `Use persona`
                                }
                            />
                        </Div>
                        <Div id="lb_footer_row">
                            <Div id="lb_actions">
                                <Div className="lb_left-buttons">
                                    <Button
                                        onClick={() => navigate('/home')}
                                        startIcon={<ChevronLeft fontSize="medium" />}
                                    >
                                        Back</Button>
                                </Div>
                                <Div className="lb_right-buttons">
                                    <Button
                                        id="lb_modal_submit"
                                        variant="contained"
                                        onClick={() => handleInvokePower()}
                                        startIcon={<PlayCircleOutline fontSize="medium" />}
                                    >
                                        Invoke Power</Button>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </>
    );
};