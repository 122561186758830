import React, { useEffect, useState } from 'react';

import './power-result.css';
import './power-running.css';
import { Box as Div } from '@mui/material';
import { Wispy } from "../wispy/wispy";

export const PowerRunning = ({className, persona, ...props}) => {
    const randomTip = () => {
        const tips = [
            {
                heading: "Discovering Powers",
                message:
                    "Click the Manage Powers icon in the pop-up menu to manage Wispy's powers.",
            },
            {
                heading: "Moving Wispy",
                message:
                    "You can relocate Wispy by simply clicking and dragging to a new spot. On touch devices, press and hold to relocate.",
            },
            {
                heading: "Moving Wispy",
                message:
                    "Holding the Alt (or Option) key on your keyboard while relocating Wispy will save its position specifically for that site.",
            },
            {
                heading: "Crafting Powers",
                message:
                    "Click the 'Edit Power' button after clicking a power to make edits. Tiny tweaks can have a big impact!",
            },
            {
                heading: "Being Specific",
                message:
                    "Selecting text lets you run a power only on the selected text.",
            },
            {
                heading: "Targeted Powers",
                message:
                    "Some powers only show on certain pages and content types, like YouTube videos and Reddit threads.",
            },
            {
                heading: "Targeted Powers",
                message:
                    "The powers that appear in Wispy are based on the page you're on.",
            },
            {
                heading: "Disappearing Wispy",
                message:
                    "To toggle Wispy on and off, click the Wispy icon in your extensions toolbar. Pin for easier access!",
            },
            {
                heading: "Sharing is Caring",
                message:
                    "If you're proud of a power you crafted, share it to the community Gallery of Powers.",
            },
        ];

        var randomIndex = Math.floor(Math.random() * tips.length);

        return tips[randomIndex];
    }

    const randomProgressMessage = () => {
        var messages = [
            "Sprinkling fairy dust on algorithms",
            "Casting the algorithmic spell",
            "Calibrating psychic radars",
            "Finding the answer in another dimension",
            "Consulting the oracle",
            "Energizing the quantum flux capacitors",
            "Dusting off ancient scrolls for wisdom",
            "Conjuring the spirit of Ada Lovelace",
            "Charging the electro-magical resonators",
            "Engaging precognitive subroutines",
            "Activating the wisdom of the ancients",
            "Bargaining with the server hamsters",
            "Doing the mathemagics",
            "Waking up the web spiders",
            "Brewing a pot of digital wisdom",
            "Invoking the code spirits",
            "Consulting the binary elders",
            "Initiating warp drive computations",
            "Solving the unsolvable, hold on...",
            "Breaking the laws of physics, just a bit",
            "Scanning the Akashic records",
            "Unfurling the scrolls of ancient code",
            "Peering through the mist of the unknown",
            "Decoding the enigma machine",
            "Cracking the Voynich manuscript",
            "Tuning the cosmic antennae",
            "Running the numbers through the quantum computer",
            "Throwing digital darts at the answer board",
            "Flipping through the Encyclopedia Galactica",
            "Whispering to the electrons",
            "Unraveling the mysteries of the universe",
            "Searching the stars for your answer",
            "Counting infinity... almost there",
            "Poking Schrödinger's cat for insights",
            "Peeking behind the quantum curtain",
            "Channeling the power of the pyramids",
            "Juggling with quarks and leptons",
            "Playing hide and seek with your answer",
            "Bending spacetime to find results",
            "Reading digital palms for answers",
            "Balancing the karmic data",
            "Whispering to the algorithms",
            "Putting the 'fun' in functions",
            "Reticulating digital splines",
            "Brewing a fresh pot of data",
            "Teaching the hamsters to code",
            "Adding secret sauce to the mix",
            "Dusting off the circuit boards",
            "Optimizing the transistor conduits",
            "Unfolding the hyperdimensional array",
            "Channeling the wisdom of the cloud",
            "Breaking out the digital abacus",
            "Shaking the magic 8-bit ball",
            "Powering up the superconductors",
            "Running the digital obstacle course",
            "Checking the voltage on the crystal ball",
            "Priming the pixel pump",
            "Poking the processing pixies",
            "Consulting the coding clover",
            "Cranking up the computation cogs",
            "Feeding the binary beavers",
            "Revving the digital engine",
            "Running the e=mc^2 equation",
            "Doing a quick loop-de-loop in the time-space continuum",
            "Cycling through quantum states",
            "Calculating the path of least resistance",
            "Rolling the digital dice",
            "Checking with the digital oracle",
            "Looking for answers in the digital aether",
            "Communing with the electrons",
            "Crunching some really hard numbers",
            "Honing the silicon crystal",
            "Revving up the render engine",
            "Warming up the data cauldron",
            "Hyping the hyperloop",
            "Flipping the digital pancake",
            "Stirring the data stew",
            "Decoding the cypher of silence",
            "Herding the electronic sheep",
            "Tuning the algorithmic guitar",
            "Solving for X, Y, and Z",
            "Baking a binary cake",
            "Pushing pixels to the limit",
            "Charging the knowledge capacitors",
            "Inflating the cloud",
            "Fine-tuning the digital symphony",
            "Syncing with the silicon rhythm",
            "Doing some high-precision guesswork",
            "Waxing the digital surfboard",
            "Crossing the data streams",
        ];

        var randomIndex = Math.floor(Math.random() * messages.length);

        return messages[randomIndex];
    }

    const [message, setMessage] = useState(randomProgressMessage());
    const [tip, setTip] = useState(randomTip());

    useEffect(() => {
        setTip(randomTip());
        const intervalId = setInterval(() => {
          // Update the text with a new string every 3 seconds
          const randomMessage = randomProgressMessage();
          setMessage(randomMessage);
        }, 3000);
    
        // Cleanup: Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Div id="running" className={`content_box ${className ? className : ''}`} {...props}>
            <Div id="wispy-container">
                <Wispy className="wispy-running" colorID={persona?.colorID} hatID={persona?.hatID}/>
            </Div>
            <Div id="modal_progress_msg">
                <label htmlFor="modal_progress">{message}</label>
                <progress id="modal_progress" max="100"></progress>
            </Div>
            <Div id="tip_box">
                <Div id="modal_tip_heading">{tip.heading}</Div>
                <Div id="modal_tip">{tip.message}</Div>
            </Div>
        </Div>
    );
};
