import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useAccessToken, useAuthActions } from "../services/auth0";
import { fetchActivePowers } from "../services/powers-api";
import { fetchInventoryPersonas } from "../services/personas-api";
import { getEnergyUsage, getStripePortalUrl } from "../services/accounts-api";
import { lb_getPowerIconById } from "../services/power-utils";
import { Wispy } from "../components/wispy/wispy"
import { Close, InfoOutlined } from '@mui/icons-material';
import {
    DialogActions,
    Stack,
    Typography,
    Tooltip,
    Card,
    CircularProgress,
    DialogContent,
    DialogTitle,
    Dialog,
    Button,
    IconButton,
    Box as Div
} from "@mui/material";

import "../styles/agent.css";

export const WISPY_WEB_URL = process.env.REACT_APP_WISPY_WEB_URL;

export const Agent = () => {
    const [powers, setPowers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [persona, setPersona] = useState(null);
    const location = useLocation();
    
    let locationParams = new URLSearchParams(location.search);
    let focusPageURL = locationParams.get('url');
    let focusPageTitle = locationParams.get('title');

    let focusPageHostname;
    if (focusPageURL) {
        try {
            let parsedFocusPageURL = new URL(focusPageURL);
            focusPageHostname = parsedFocusPageURL.hostname;
        } catch {
            // If URL is invalid, do nothing (i.e. don't set the url param)
        }
        
    }

    const [energyDetail, setEnergyDetail] = useState(null);
    const [userProfile, setUserProfile] = useState(null);

    const [isEnergyDialogOpen, setIsEnergyDialogOpen] = useState(false);
    const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
    const [isPersonaDialogOpen, setIsPersonaDialogOpen] = useState(false);
    const [isPowersOpen, setIsPowersOpen] = useState(false);

    const accessToken = useAccessToken();
    const { signOut, getUser } = useAuthActions();

    const navigate = useNavigate();

    const toggleDialog = (setter) => (value) => {
        setter(value);
    };

    const handleOpenSettings = () => { toggleDialog(setIsSettingsDialogOpen)(true); }
    const handleCloseSettings = () => { toggleDialog(setIsSettingsDialogOpen)(false); }

    const handleOpenEnergy = () => { toggleDialog(setIsEnergyDialogOpen)(true); }
    const handleCloseEnergy = () => { toggleDialog(setIsEnergyDialogOpen)(false); }

    const handleOpenPersona = () => { toggleDialog(setIsPersonaDialogOpen)(true); }
    const handleClosePersona = async () => {toggleDialog(setIsPersonaDialogOpen)(false); }

    const buildInvokePath = (power) => {
        let path = `/invoke/${ power._id }`;
        if (focusPageURL) {
            let params = new URLSearchParams()
            // verify url is valid
            try {
                new URL(focusPageURL);
                params.set("url", focusPageURL);
            } catch {
                // If URL is invalid, do nothing (i.e. don't set the url param)
            }
            if (focusPageTitle) {
                params.set("title", focusPageTitle);
            }
            path += "?";
            path += params.toString();
        }
        return path;
    }

    const openSubscriptionPortal = async () => {
        if (accessToken) {
            let portalUrl = await getStripePortalUrl(accessToken);
            window.open(portalUrl, "_blank");
        }
    };

    const reloadPowers = useCallback(async () => {
        if (accessToken) {
            setIsLoading(true);
            try {
                const data = await fetchActivePowers(accessToken, true);
                setPowers(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [accessToken]);

    useEffect(() => {
        const fetchPowers = async () => {
            setIsLoading(true);
            try {
                const data = await fetchActivePowers(accessToken);
                setPowers(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (accessToken) {
            fetchPowers();
        }
    }, [accessToken]);

    let colorCounter = 0;
    const _tempPowerColors = ["#6C2CFC", "#4B3BF8", "#FD2270"];
    const createPowerElement = useCallback((power) => {
        //eslint-disable-next-line
        colorCounter = (colorCounter + 1) % (_tempPowerColors.length);
        return (
            <Card className="lb_power-row" key={power._id}>
                <Div className="lb_power-button" onClick={() => navigate(buildInvokePath(power))}>
                    <Div className="lb_power-icon" style={{ backgroundColor: _tempPowerColors[colorCounter] }}>
                        {lb_getPowerIconById(power.iconID)}
                    </Div>
                    <Div className="lb_label-text-box">
                        <span className="lb_label-text">
                            {power.name["en-US"]}
                            <Typography className="lb_creator">
                                {power._creatorHandle}
                            </Typography>
                        </span>
                    </Div>
                </Div>
            </Card>
        );
    }, []);

    const powersElements = useMemo(() => {
        if (powers.length > 0) {
            return powers.map(power => createPowerElement(power));
        } else {
            return <Button variant="contained" style={{ margin: '24px' }} onClick={() => window.open(`${WISPY_WEB_URL}/powers`, '_blank')}>Explore Gallery of Powers</Button>
        }
    }, [powers, createPowerElement]);

    function updateEnergyMeter(energy) {
        if (energy) {
            let remainingEnergy = 0;
            let energyMeter = document.getElementById("lb_progress");
            if (energyMeter) {
                remainingEnergy = 100 - (energy?.usage?.percent_used || 0);
                if (remainingEnergy >= 0 && remainingEnergy <= 100) {
                    energyMeter.value = remainingEnergy;
                } else {
                    console.error("Invalid energy percentage: " + remainingEnergy);
                    energyMeter.value = 0;
                }
            } else {
                console.error("Energy meter element not found");
            }
        }
    };

    function updateEnergyDetail(energy) {
        if (energy) {
            const remainingEnergy = 100 - (energy?.usage?.percent_used || 0);
            const periodEndTimestamp = energy.usage.period_end * 1000;
            const currentTime = Date.now();
            let remainingTime = periodEndTimestamp - currentTime;
            let remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
            if (remainingDays < 0) remainingDays = 0;
            let energyDetail = `You have ${remainingEnergy}% Energy remaining this month. Next refill in ${remainingDays} days.`;
            setEnergyDetail(energyDetail);
        } else {
            setEnergyDetail("Energy usage not available. Try again later.");
        }
    };

    useEffect(() => {
        const fetchEnergyAndUpdateMeter = async () => {
            if (accessToken) {
                let energy = 0;
                try {
                    energy = await getEnergyUsage(accessToken);
                    updateEnergyMeter(energy);
                    updateEnergyDetail(energy);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchEnergyAndUpdateMeter();
    }, [accessToken]);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if(accessToken) {
                try {
                    const data = await getUser();
                    setUserProfile(data);
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    const fetchUserPersona = useCallback(async () => {
        if (accessToken) {
            try {
                const data = await fetchInventoryPersonas(accessToken);
                const [selectedPersona] = data.filter((persona) => persona?._isUsersDefault)
                setPersona(selectedPersona)
            } catch (error) {
                console.error(error);
            }
        } 
    }, [accessToken]);

    // initial fetch of persona
    useEffect(() => {
        fetchUserPersona();
    }, [fetchUserPersona])

    // update persona and powers when refocused
    useEffect(() => {
        const handleFocus = () => {
            if (isPowersOpen) {
                reloadPowers();
                setIsPowersOpen(false);
            }
            if (isPersonaDialogOpen) {
                fetchUserPersona();
            }
        };
        window.addEventListener('focus', handleFocus);
        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [reloadPowers, isPersonaDialogOpen, fetchUserPersona, isPowersOpen]);


    const handleOpenPowers = () => {
        setIsPowersOpen(true);
        window.open(`${WISPY_WEB_URL}/powers`, '_blank');
    };

    return (
        <Div>
            <Div className="lb_content-layout">
                <Div id="lb_cyberwell_agent">
                    <Div id="lb_persona">
                        <Div id="lb_persona_content">
                            <Wispy id="lb_persona_icon" colorID={persona?.colorID} hatID={persona?.hatID} onClick={handleOpenPersona}/>
                            <Typography alt="Speech Bubble" id="lb_persona_msg">Hey! Here are the powers that I can invoke for you {focusPageHostname ? `on this ${focusPageHostname} web page!` : "here!"}</Typography>
                        </Div>
                        <Div id="lb_persona_energy_wrapper" onClick={handleOpenEnergy}>
                            <Typography>Wispy's energy</Typography> <progress id="lb_progress" max="100" value="0"></progress>
                            <InfoOutlined fontSize="small" />
                        </Div>
                    </Div>

                    <Div className="lb_powerlist" id="lb_inventory">
                        {isLoading ? (
                            <Div className="lb_inventory_loading">
                                <CircularProgress />
                            </Div>
                        ) : (
                            <Div>
                                <Div>{powersElements}</Div>
                            </Div>
                        )}
                    </Div>

                    <Div id="lb_footer">
                        <Div id="lb_utilities">
                            <Tooltip title="Help and Documentation" placement="top-start">
                                <IconButton onClick={() => window.open(`${WISPY_WEB_URL}/help`, '_blank')}>
                                    <svg className="lb_utility-glyph lb_glyph-small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M11.29,15.29a1.58,1.58,0,0,0-.12.15.76.76,0,0,0-.09.18.64.64,0,0,0-.06.18,1.36,1.36,0,0,0,0,.2.84.84,0,0,0,.08.38.9.9,0,0,0,.54.54.94.94,0,0,0,.76,0,.9.9,0,0,0,.54-.54A1,1,0,0,0,13,16a1,1,0,0,0-.29-.71A1,1,0,0,0,11.29,15.29ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM12,7A3,3,0,0,0,9.4,8.5a1,1,0,1,0,1.73,1A1,1,0,0,1,12,9a1,1,0,0,1,0,2,1,1,0,0,0-1,1v1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,12,7Z" />
                                    </svg>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Manage your Powers" placement="top">
                                <IconButton onClick={handleOpenPowers}>
                                    <svg className="lb_utility-glyph lb_glyph-big" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path d="M10 13H4C3.73478 13 3.48043 13.1054 3.29289 13.2929C3.10536 13.4804 3 13.7348 3 14V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H10C10.2652 21 10.5196 20.8946 10.7071 20.7071C10.8946 20.5196 11 20.2652 11 20V14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13ZM9 19H5V15H9V19ZM20 3H14C13.7348 3 13.4804 3.10536 13.2929 3.29289C13.1054 3.48043 13 3.73478 13 4V10C13 10.2652 13.1054 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11H20C20.2652 11 20.5196 10.8946 20.7071 10.7071C20.8946 10.5196 21 10.2652 21 10V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3V3ZM19 9H15V5H19V9ZM20 16H18V14C18 13.7348 17.8946 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13C16.7348 13 16.4804 13.1054 16.2929 13.2929C16.1054 13.4804 16 13.7348 16 14V16H14C13.7348 16 13.4804 16.1054 13.2929 16.2929C13.1054 16.4804 13 16.7348 13 17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18H16V20C16 20.2652 16.1054 20.5196 16.2929 20.7071C16.4804 20.8946 16.7348 21 17 21C17.2652 21 17.5196 20.8946 17.7071 20.7071C17.8946 20.5196 18 20.2652 18 20V18H20C20.2652 18 20.5196 17.8946 20.7071 17.7071C20.8946 17.5196 21 17.2652 21 17C21 16.7348 20.8946 16.4804 20.7071 16.2929C20.5196 16.1054 20.2652 16 20 16ZM10 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V10C3 10.2652 3.10536 10.5196 3.29289 10.7071C3.48043 10.8946 3.73478 11 4 11H10C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10V4C11 3.73478 10.8946 3.48043 10.7071 3.29289C10.5196 3.10536 10.2652 3 10 3V3ZM9 9H5V5H9V9Z" />
                                    </svg>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Manage your Powers" placement="top-end">
                                <IconButton onClick={handleOpenSettings}><svg className="lb_utility-glyph lb_glyph-small" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24">
                                    <path d="M19.9,12.66a1,1,0,0,1,0-1.32L21.18,9.9a1,1,0,0,0,.12-1.17l-2-3.46a1,1,0,0,0-1.07-.48l-1.88.38a1,1,0,0,1-1.15-.66l-.61-1.83A1,1,0,0,0,13.64,2h-4a1,1,0,0,0-1,.68L8.08,4.51a1,1,0,0,1-1.15.66L5,4.79A1,1,0,0,0,4,5.27L2,8.73A1,1,0,0,0,2.1,9.9l1.27,1.44a1,1,0,0,1,0,1.32L2.1,14.1A1,1,0,0,0,2,15.27l2,3.46a1,1,0,0,0,1.07.48l1.88-.38a1,1,0,0,1,1.15.66l.61,1.83a1,1,0,0,0,1,.68h4a1,1,0,0,0,.95-.68l.61-1.83a1,1,0,0,1,1.15-.66l1.88.38a1,1,0,0,0,1.07-.48l2-3.46a1,1,0,0,0-.12-1.17ZM18.41,14l.8.9-1.28,2.22-1.18-.24a3,3,0,0,0-3.45,2L12.92,20H10.36L10,18.86a3,3,0,0,0-3.45-2l-1.18.24L4.07,14.89l.8-.9a3,3,0,0,0,0-4l-.8-.9L5.35,6.89l1.18.24a3,3,0,0,0,3.45-2L10.36,4h2.56l.38,1.14a3,3,0,0,0,3.45,2l1.18-.24,1.28,2.22-.8.9A3,3,0,0,0,18.41,14ZM11.64,8a4,4,0,1,0,4,4A4,4,0,0,0,11.64,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11.64,14Z" />
                                </svg>
                                </IconButton>
                            </Tooltip>
                        </Div>
                    </Div>

                    <Dialog open={isSettingsDialogOpen} onClose={handleCloseSettings}>
                        <DialogTitle sx={{ m: 0, p: 2 }}>
                            Wispy's Settings
                        </DialogTitle>
                        <IconButton edge="end" color="inherit" onClick={handleCloseSettings} aria-label="close" style={{ position: 'absolute', right: 16, top: 8 }}>
                            <Close />
                        </IconButton>
                        <DialogContent dividers>
                            <Stack spacing={1}>
                                <Typography variant="h6">Account</Typography>
                                <Typography>Signed in as <strong>{userProfile?.name} &lt;{userProfile?.email}&gt;</strong></Typography>
                                <Button onClick={signOut} variant="outlined">Sign Out</Button>
                                <Typography variant="h6">Membership</Typography>
                                <Typography>{energyDetail}</Typography>
                                <Button onClick={openSubscriptionPortal} variant="outlined">Update or cancel plan</Button>
                                <Typography variant="h6">Debug</Typography>
                                <Button onClick={reloadPowers} variant="outlined">Reload Powers</Button>
                            </Stack>
                        </DialogContent>
                    </Dialog>

                    <Dialog open={isEnergyDialogOpen} onClose={handleCloseEnergy}>
                        <DialogTitle sx={{ m: 0, p: 2 }}>
                            Wispy's Energy
                        </DialogTitle>
                        <IconButton edge="end" color="inherit" onClick={handleCloseEnergy} aria-label="close" style={{ position: 'absolute', right: 16, top: 8 }}>
                            <Close />
                        </IconButton>
                        <DialogContent dividers>
                            <Typography variant="subtitle1">{energyDetail}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={openSubscriptionPortal} variant="contained">Upgrade or change plan</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={isPersonaDialogOpen} onClose={handleClosePersona}>
                        <DialogTitle sx={{ m: 0, p: 2, marginRight: 4 }}>
                            Wispy's Persona
                        </DialogTitle>
                        <IconButton edge="end" color="inherit" onClick={handleClosePersona} aria-label="close" style={{ position: 'absolute', right: 16, top: 8 }}>
                            <Close />
                        </IconButton>
                        <Wispy id="lb_persona_icon" colorID={persona?.colorID} hatID={persona?.hatID}/>
                        <DialogContent dividers>
                            <Button variant="contained" fullWidth onClick={() => window.open(`${WISPY_WEB_URL}/personas`, '_blank')}>Change Persona</Button>
                        </DialogContent>
                    </Dialog>

                </Div>
            </Div>
        </Div>
    );
};